class Timer {
    constructor(Duration,workTime) {
        this.workTime = workTime*1000;
        this.Duration = Duration*1000;
        this.remainingTime = this.Duration-this.workTime;
        console.log(this.remainingTime)
        this.currentWorkTime=0;
        this.intervalId = null;
        this.startTime = null;
    }

    start(startTime) {
        if (this.intervalId) return; 
        console.log(startTime,"start time")
        this.startTime=startTime
        console.log(this.remainingTime,this.workTime)
        this.remainingTime=this.remainingTime-(new Date()-this.startTime)
        this.currentWorkTime += new Date()-startTime
        console.log(this.remainingTime)
          // Timer is already running
        this.intervalId = setInterval(() => {
            this.currentWorkTime= new Date()-this.startTime;
            console.log(this.currentWorkTime)
            this.remainingTime=this.Duration-this.currentWorkTime-this.workTime;
            if (this.onUpdate) {
                this.onUpdate(this.getTimeLeft());
            }
            }
        , 1000);
    }

    stop() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
            this.workTime=this.currentWorkTime+this.workTime
            this.remainingTime=this.Duration-this.workTime;
            return this.workTime;
        }
    }

    getTimeLeft() {
        let sign = "";
        let time_left = this.remainingTime;
        if (this.remainingTime < 0) {
            sign="-"
            time_left=Math.abs(this.remainingTime)
        }
        let hours = Math.floor(time_left / 3600000);
        let minutes = Math.floor((time_left % 3600000) / 60000);
        let seconds = Math.round((time_left % 60000) / 1000);

        return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
}
export default Timer;