import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Task from '../utils/TaskMaster'; // Assuming Task class is imported from Task.js

const UserHome = () => {
  const [tasks, setTasks] = useState([]);
  const [level, setLevel] = useState(1);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [UserStats, setUserStats] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch('/api/user-home', {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch tasks');
        }
        //console.log(response.json())
        const responseJson = await response.json();
        const stats = await responseJson["user_stats"];
        const newTasks = await responseJson["tasks"];
        setUserStats(stats);
        setTasks(newTasks.map(task => new Task(task)));
      } catch (error) {
        console.error('Error fetching tasks:', error);
        alert('Failed to load the tasks. Please try again.');
      }
    };

    fetchTasks();
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await fetch('/api/upload', {
          method: 'POST',
          credentials: 'include',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload file');
        }

        const newTasks = await response.json()["tasks"];
        const stats = await response.json()["user_stats"];
        setUserStats(stats);
        setTasks(newTasks.map(task => new Task(task)));
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to load the tasks. Please try again.');
      }
    }
  };
  const handleUnassign = async () => {
    let foundTask = null;
    if (selectedTask) {
      console.log(tasks)
      for (let task of tasks){
        if (task.getTask(selectedTask)){
          foundTask=task.getTask(selectedTask);
        };
      };
        
      foundTask.assigned=false;
      console.log(foundTask.toJSON())
      try {
        const response = await fetch(`/api/task/${selectedTask}`, {
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(foundTask.toJSON()),
          credentials: 'include',
          method: "PUT",
        });
    handleMenuClose();
    }
  catch (error) {
    console.error("Error:", error);
    }
  };
  };
  const handleEditClick = () => {
    if (selectedTask) {
      navigate(`/edit-task/${selectedTask}`);
    }
    handleMenuClose();
  };

  const handleTaskClick = (taskId) => {
    navigate('/task-view', { state: { taskId } });
  };

  const handleMenuOpen = (event, taskId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedTask(taskId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTask(null);
  };

  const getMaxDepth = (tasks) => {
    if (!tasks || tasks.length === 0) return 0;
    return 1 + Math.max(...tasks.map(task => getMaxDepth(task.subtasks || [])));
  };

  const handleLevelIncrease = () => {
    const maxDepth = getMaxDepth(tasks);
    setLevel((prevLevel) => (prevLevel < maxDepth ? prevLevel + 1 : prevLevel));
  };

  const handleLevelDecrease = () => {
    setLevel((prevLevel) => Math.max(prevLevel - 1, 1));
  };

  const handleCategoryChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const sortTasksRecursively = (tasks) => {
    return tasks
      .map(task => ({
        ...task,
        subtasks: sortTasksRecursively(task.subtasks || []), // Sort subtasks recursively
      }))
      .sort((a, b) => a.priority - b.priority || new Date(a.creation_date) - new Date(b.creation_date));
  };

  const filterAndSortTasks = (tasks) => {
    const filteredTasks = tasks.filter(task => !categoryFilter || task.category === categoryFilter);
    return sortTasksRecursively(filteredTasks);
  };

  const renderTasks = (tasks, currentLevel = 1) => {
    if (currentLevel > level) return null;

    return tasks.map((task) => (
      <div key={task.id} style={{ marginLeft: `${(currentLevel - 1) * 20}px` }} className="task-item">
        <Button
          onClick={() => handleTaskClick(task.id)}
          variant="outlined"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0', width: '100%' }}
        >
          {task.title} 🔥 {task.streak}
          <IconButton
            aria-controls="task-menu"
            aria-haspopup="true"
            onClick={(event) => handleMenuOpen(event, task.id)}
            size="small"
          >
            <MoreVertIcon />
          </IconButton>
        </Button>
        {task.subtasks && task.subtasks.length > 0 && renderTasks(task.subtasks, currentLevel + 1)}
      </div>
    ));
  };

  const getCategoryOptions = () => {
    const categories = new Set(tasks.map((task) => task.category));
    return Array.from(categories);
  };

  return (
    <div>
      <h1>Task Manager</h1>
      <div>
        <h2>Stats</h2>
        <p>Tasks Completed: {UserStats?.completed_tasks}</p>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Button onClick={handleLevelIncrease}>
          show more levels
        </Button>
        <Button className='arcade-button' onClick={handleLevelDecrease}>
          show fewer levels
        </Button>
        <Button className='arcade-button' onClick={() => navigate("/add-task")}>
        Add Task
      </Button>
        <select
          value={categoryFilter}
          onChange={handleCategoryChange}
          style={{ marginLeft: '20px', padding: '5px' }}
        >
          <option value="">All Categories</option>
          {getCategoryOptions().map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      <div id="task-list-container">
        {renderTasks(filterAndSortTasks(tasks))}
      </div>
      <input type="file" onChange={handleFileChange} />

      <Menu
        id="task-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleUnassign}>Unassign</MenuItem>
        <MenuItem onClick={() => console.log(`Complete Task ${selectedTask}`)}>Complete</MenuItem>
      </Menu>
    </div>
    
  );
};

export default UserHome;
