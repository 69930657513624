import Timer from './timer.js';
class Task {
    constructor({
        id,
        user_id,
        creation_date,
        title,
        parent_id,
        category,
        complete,
        frequency,
        working_time,
        importance,
        priority,
        due_date,
        duration,
        streak,
        assigned,
        subtasks = []
    }) {
        this.id = id;
        this.user_id = user_id;
        this.creation_date = creation_date ? new Date(creation_date) : null;
        this.title = title;
        this.parent_id = parent_id;
        this.category = category;
        this.complete = complete ? new Date(complete) : null;
        this.frequency = frequency;
        this.working_time = parseInt(working_time,10);
        this.importance = parseInt(importance,10);
        this.priority = priority;
        this.duration = duration;
        this.streak = streak;
        this.assigned = assigned;
        this.due_date = due_date ? new Date(due_date) : null;
        this.subtasks = subtasks.map(subtask => new Task(subtask));
    }
  
    // Method to check if the task is complete
    isComplete() {
        return this.complete !== null;
    }
  
    // Method to get all leaf subtasks (tasks without further subtasks), with an optional filter for completion status
    getLeafSubtasks({ complete = null } = {}) {
        if (this.subtasks.length === 0) {
            // If there are no subtasks, return itself if it matches the completion filter
            if (complete === null || (complete === false && !this.isComplete()) || (complete === true && this.isComplete())) {
                return [this];
            }
            return [];
        }
        // Otherwise, recursively get leaf subtasks from all children
        return this.subtasks.flatMap(subtask => subtask.getLeafSubtasks({ complete }));
    }
  
    // Convert back to JSON format
    toJSON() {
        return {
            id: this.id,
            user_id: this.user_id,
            creation_date: this.creation_date ? this.creation_date.toISOString() : null,
            title: this.title,
            parent_id: this.parent_id,
            category: this.category,
            complete: this.complete ? this.complete.toISOString() : null,
            frequency: this.frequency,
            working_time: this.working_time,
            importance: this.importance,
            due_date: this.due_date ? this.due_date.toISOString() : null,
            duration: this.duration,
            assigned: this.assigned,
            subtasks: this.subtasks.map(subtask => subtask.toJSON())
        };
    }
  
    // Method to add a subtask to the current task
    addSubtask(subtaskData) {
        const newSubtask = new Task({
            ...subtaskData,
            parent_id: this.id // Ensure the subtask's parent_id is set to this task's id
        });
        
        this.subtasks.push(newSubtask);
    }
    getTask(taskId) {
        // Check if the current task matches the taskId
        if (this.id === taskId) {
            return this;
        }
  
        // Recursively check subtasks
        for (let subtask of this.subtasks) {
            const foundTask = subtask.getTask(taskId);
            if (foundTask) {
                return foundTask;
            }
        }
  
        return null; // Task not found
    }
  
    // Method to remove a subtask by its id
    removeSubtask(subtaskId) {
        this.subtasks = this.subtasks.filter(subtask => subtask.id !== subtaskId);
    }
    updateTask(updatedTask) {
        // Check if the current task matches the updatedTask id
        if (this.id === updatedTask.id) {
            // Update the properties of the current task with the properties of updatedTask
            this.user_id = updatedTask.user_id;
            this.creation_date = updatedTask.creation_date ? new Date(updatedTask.creation_date) : null;
            this.title = updatedTask.title;
            this.parent_id = updatedTask.parent_id;
            this.category = updatedTask.category;
            this.complete = updatedTask.complete ? new Date(updatedTask.complete) : null;
            this.frequency = updatedTask.frequency;
            this.working_time = updatedTask.working_time;
            this.importance = updatedTask.importance;
            this.due_date = updatedTask.due_date ? new Date(updatedTask.due_date) : null;
  
            // Recursively update subtasks
            this.subtasks = updatedTask.subtasks.map(subtaskData => {
                const existingSubtask = this.getTask(subtaskData.id);
                if (existingSubtask) {
                    // Update the existing subtask
                    existingSubtask.updateTask(subtaskData);
                    return existingSubtask;
                } else {
                    // Add the new subtask if it doesn't exist
                    return new Task(subtaskData);
                }
            });
            return true;
        }
  
        // If not found at the current level, search recursively in subtasks
        for (let subtask of this.subtasks) {
            if (subtask.updateTask(updatedTask)) {
                return true;
            }
        }
  
        return false; // Task not found
    }
    getPriority() {
        const now = new Date();
        let daysUntilDue = this.due_date ? (this.due_date - now) / (1000 * 60 * 60 * 24) : Infinity;
        
        // Apply logarithmic transformation (add 1 to avoid log(0))
        daysUntilDue = daysUntilDue > 0 ? daysUntilDue : 0;
        
        // Calculate Euclidean distance to (0, 10)
        const distance = Math.sqrt(Math.pow(daysUntilDue, 2) + Math.pow(10 - this.importance, 2));
        
        return distance;
    }
    getParentTasksByLeafPriority() {
        // Get all leaf subtasks with their priority levels
        const leafSubtasks = this.getLeafSubtasks();
    
        // Map each leaf's priority to its parent task's ID
        const parentPriorityMap = new Map();
    
        leafSubtasks.forEach(leaf => {
            if(leaf.complete === null){
                const parentId = leaf.parent_id;
                const priority = leaf.getPriority();
        
                if (!parentPriorityMap.has(parentId)) {
                    parentPriorityMap.set(parentId, priority);
                } else {
                    // Only update if the new priority is higher (i.e., lower distance)
                    const currentPriority = parentPriorityMap.get(parentId);
                    if (priority < currentPriority) {
                        parentPriorityMap.set(parentId, priority);
                    }
                }
            }
        });
            // Convert the map to an array of objects with `parent_id` and `priority`
    const parentPriorities = Array.from(parentPriorityMap, ([parent_id, priority]) => ({ parent_id, priority }));
    // Sort by priority from highest to lowest (lowest distance first)
    parentPriorities.sort((a, b) => a.priority - b.priority);
    console.log(parentPriorities);

    return parentPriorities;
    }
  }
class AssignedTask {
    constructor({
        id,
        user_id,
        creation_date,
        title,
        category,
        complete,
        frequency,
        working_time,
        importance,
        parent_id,
        due_date,
        start,
        duration
    }) {
        this.id = id;
        this.user_id = user_id;
        this.creation_date = creation_date ? new Date(creation_date) : null;
        this.title = title;
        this.parent_id = parent_id;
        this.category = category;
        this.complete = complete ? new Date(complete) : null;
        this.frequency = frequency;
        this.duration =duration ? parseInt(duration,10):0;
        this.start = start ? new Date(start) : null;
        this.working_time = working_time? parseInt(working_time,10):0;
        this.importance = parseInt(importance,10);
        this.due_date = due_date ? new Date(due_date) : null;
        console.log("working time",this.working_time)
        this.timer = new Timer(this.duration, this.working_time);
        
    }
    toJSON() {
        return {
            id: this.id,
            user_id: this.user_id,
            creation_date: this.creation_date ? this.creation_date.toISOString() : null,
            title: this.title,
            parent_id: this.parent_id,
            category: this.category,
            complete: this.complete ? this.complete.toISOString() : null,
            frequency: this.frequency,
            working_time: this.timer.workTime/1000,
            start: this.start ? this.start.toISOString() : null,
            due_date: this.due_date ? this.due_date.toISOString() : null,
        };
    }
  
    // Method to check if the task is complete
    isComplete() {
        return this.complete !== null;
    }

    
}
  
  
export { AssignedTask };
export default Task;
  