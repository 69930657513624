import React,{ useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem, IconButton } from '@mui/material';
import { Sparkles, CheckCircle, Clock, Flame, Timer, Repeat, Egg, Infinity, ListTree } from "lucide-react";
import "./LandingPage.css";
import jsonp from "jsonp";



const LandingPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState(""); // 'success', 'error', or ''
    const [message, setMessage] = useState("");
      
    const handleSubmit = (e) => {
    e.preventDefault();
    const url = `https://gmail.us12.list-manage.com/subscribe/post-json?u=89238f036b5b1cd0b1530fb07&id=87046c0235&EMAIL=${encodeURIComponent(email)}`;
      
          jsonp(url, { param: "c" }, (err, data) => {
            if (err || data.result !== "success") {
              setStatus("error");
              setMessage(data?.msg || "Something went wrong.");
            } else {
              setStatus("success");
              setMessage("Thanks for subscribing!");
              setEmail("");
            }
        });
    };
    return (
      <div className="landing-page">
        <div className="landing-content">
          <h1 className="landing-title">
           Dopatize
          </h1>
          <p className="landing-subtitle">
            A task managment app designed to help motivate you and make you feel good about what you have done.
            The app is designed with the adhd brain in mind.
            Dopatize allows you to brake down tasks in to smaller and smaller tasks until you are left with a task that is so small that it is easy to do.
          </p>
  
          <div className="landing-buttons">
            <form onSubmit={handleSubmit} className="email-form">
            <input
                id="email"
                name="EMAIL"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
            />
                <Button type="submit" className="email-button">
                  Join the waitlist
                </Button>
            </form>
          </div>
          <Button  onClick={() => navigate("/sign-in")}>
                "have an account? Sign in"
            </Button>
          <div className="feature-grid">
            <FeatureCard
              icon={<Sparkles className="icon icon-blue" />}
              title="Automater Priorization"
              description="The app will keep track of what to do next so you don't have to."
            />
             <FeatureCard
              icon={<Repeat className="icon icon-mint" />}
              title="Reacuring Tasks"
              description="set tasks on a schedule and the app will take care of the rest."
            />
            <FeatureCard
              icon={<Clock className="icon icon-coral" />}
              title="Keep track of time"
              description="Every task has a timer. For reacuring tasks the timer will update based on your previous completions."
            />
            <FeatureCard
              icon={<Flame className="icon icon-coral" />}
              title="Streaks"
              description="for all reacuring tasks you streak count will go up for compeleting a task before the due date."
            />
            <FeatureCard
              icon={<ListTree className="icon icon-blue" />}
              title="Unlimited levels of subtasks"
              description="Keep braking down a task as small at you want."
            />
          </div>
        </div>
      </div>
    );
  }
  
  function FeatureCard({ icon, title, description }) {
    return (
      <div className="feature-card">
        <div className="feature-icon">{icon}</div>
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
      </div>
    );
  }
export default LandingPage;